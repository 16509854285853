import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const Form = ({ n, label, type, placeholder, value, setValue, required }) => {
  if (type === "image") {
    return (
      <div className="question-container">
        <div className="circle">{n}</div>
        <h1 className="question">{label}</h1>
        {required ? 
          <input
          type="file"
          id="img"
          name="img"
          accept="image/*"
          required
          onChange={(e) => {
            return setValue(e.target.files[0]);
          }}
        /> : 
        <input
          type="file"
          id="img"
          name="img"
          accept="image/*"
          onChange={(e) => {
            return setValue(e.target.files[0]);
          }}
        />
        }
        
      </div>
    );
  }
  if (type === "ckeditor") {
    return (
      <div className="question-container">
        <div className="circle">{n}</div>
        <h1 className="question">{label}</h1>
        <CKEditor
          editor={ClassicEditor}
          data={value}
          onReady={(editor) => {
            // You can store the "editor" and use when it is needed.
            console.log("Editor is ready to use!", editor);
            console.log(label)
          }}
          onChange={(event, editor) => {
            // const data = editor.getData();
            setValue(editor.getData());
            // console.log( { event, editor, data } );
          }}
          onBlur={(event, editor) => {
            console.log("Blur.", editor);
          }}
          onFocus={(event, editor) => {
            console.log("Focus.", editor);
          }}
        />
      </div>
    );
  }
  
  
  if (type === "ckeditor-serves") {
    let init = value ? value : `<figure class="table"><table><tbody><tr><td>S.No</td><td>Churches</td><td>Institutions</td><td>Year</td></tr><tr><td>1</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr><tr><td>2</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr></tbody></table></figure>` 
    return (
      <div className="question-container">
        <div className="circle">{n}</div>
        <h1 className="question">{label}</h1>
        <CKEditor
          editor={ClassicEditor}
          data={init}
          onReady={(editor) => {
            // You can store the "editor" and use when it is needed.
            console.log("Editor is ready to use!", editor);
          }}
          onChange={(event, editor) => {
            const data = editor.getData();
            setValue(editor.getData());
            console.log( { event, editor, data } );
          }}
          onBlur={(event, editor) => {
            console.log("Blur.", editor);
          }}
          onFocus={(event, editor) => {
            console.log("Focus.", editor);
          }}
        />
      </div>
    );
  }
  return (
    <div className="question-container">
      <div className="circle">{n}</div>
      <h1 className="question">{label}</h1>
      {required ? 
        <input
        type={type}
        placeholder={placeholder}
        value={value}
        required
        onChange={(e) => setValue(e.target.value)}
      /> : 
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        
      />}
      
    </div>
  );
};

export default Form;


// "<figure class="table"><table><tbody><tr><td>S.No</td><td>Churches</td><td>Institutions</td><td>Year</td></tr><tr><td>1</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr><tr><td>2</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr></tbody></table></figure>"