import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Post from "../../API/Post";
import Form from "../Form/Form";
import Error from "../Error/Error";
import Success from "../Success/Success";
import getRequest from "../../API/getRequest";

const UpdateBlog = () => {

    const { id } = useParams();
    const [title, settitle] = useState("")
    const [slug, setslug] = useState("")
    const [authors, setauthors] = useState([])
    const [author, setauthor] = useState("")
    const [content, setcontent] = useState("")
    const [date, setdate] = useState("")
    const [image, setimage] = useState("");
    const [isLoading, setisLoading] = useState(false);
    const [error, seterror] = useState(false);
    const [success, setsuccess] = useState(false);

    useEffect(() => {
        setisLoading(true);
        getRequest(`${process.env.REACT_APP_API_URI}/blog/${id}`).then((res) => {
            console.log(res);
            if (res.status === "ok") {
                seterror(false);
                settitle(res.blog.title);
                setcontent(res.blog.content);
                setauthor(res.blog.author._id);
                setdate(res.blog.date);
                setslug(res.blog.slug);
                setisLoading(false);
            } else {
                seterror(res.msg);
            }
        });
    }, []);
    const clearForm = () => {
        settitle("")
        setslug("")
        setauthor("")
        setcontent("")
        setdate("")
        setimage("")
    };

    useEffect(() => {
        getRequest(`${process.env.REACT_APP_API_URI}/authors`).then((res) => {
            if (res.status === "ok") {
                console.log(res);
                setauthors(res.author);
            }
        });
    }, []);

    const submitForm = (e) => {
        setisLoading(true);
        e.preventDefault();
        const formData = new FormData();
        formData.append("id", slug)
        formData.append("image", image);
        formData.append("title", title)
        formData.append("author", author)
        formData.append("content", content)
        formData.append("date", date)
        formData.append("slug", slug)


        Post(`${process.env.REACT_APP_API_URI}/update-blog`, formData)
            .then((res) => {
                if (res.status === "ok") {
                    clearForm();
                    console.log(res);
                    setisLoading(false);
                    setsuccess(true);
                } else {
                    setisLoading(false);
                    seterror(true);
                    console.log(res);
                }
            })
            .catch((err) => {
                setisLoading(false);
                seterror(true);
                console.log(err);
            });
    };

    if (success) {
        return (
            <>
                <Success
                    success={success}
                    message={"Blog updated Successfully"}
                />
            </>
        );
    }

    if (isLoading) {
        return <div className="loader"></div>;
    }
    return (
        <div className="create-container">
            <Error error={error} />
            <h1>Add Blog</h1>
            <form className="create" onSubmit={(e) => submitForm(e)}>
                <div className="question-container">
                    <div className="circle">1</div>
                    <h1 className="question">Author</h1>
                    <select
                        name="fundRaiser"
                        id=""
                        value={author}
                        onChange={(e) => setauthor(e.target.value)}
                    >
                        <option value="nill">Select an author</option>
                        {authors.map((author) => (
                            <option value={author._id}>
                                {author.name}
                            </option>
                        ))}
                    </select>
                </div>
                <Form
                    n="1"
                    label="title"
                    type="text"
                    placeholder="Title"
                    value={title}
                    setValue={settitle}
                    required={true}
                />
                 <Form
                    n="1"
                    label="slug (no spaces, use - to seperate words)"
                    type="text"
                    placeholder="slug"
                    value={slug}
                    setValue={setslug}
                    required={true}
                />
                <Form
                    n="2"
                    label="Content"
                    type="ckeditor"
                    placeholder="content"
                    value={content}
                    setValue={setcontent}
                />
                <Form
                    n="3"
                    label="Date"
                    type="date"
                    placeholder="Date"
                    value={date}
                    setValue={setdate}
                    required={true}
                />
                <Form
                    n="4"
                    label="Image"
                    type="image"
                    placeholder="Image"
                    setValue={setimage}
                    required={true}
                />



                <input type="submit" className="btn" value="Update Blog" />
            </form>
        </div>
    )
}

export default UpdateBlog
