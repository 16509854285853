import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./Dashboard.css";
import AddCampaign from "../Campaigns/AddCampaign";
import AddParish from "../Parishes/AddParish";
import AddPatron from "../Patrons/AddPatron";
import AddAuthor from "../Blogs/AddAuthor";
import GoogleLogoutBtn from "../GoogleAuth/GoogleLogoutBtn";
import AddUser from "../AddUser/AddUser";
import { useState } from "react";
import { useEffect } from "react";
import UpdateCampaign from "../Campaigns/UpdateCampaing";
import UpdateSearch from "../Update/UpdateSearch";
import UpdateParish from "../Parishes/UpdateParish";
import UpdatePatron from "../Patrons/UpdatePatron";
import UpdateBlog from "../Blogs/UpdateBlog";
import Logs from "../Logs/Logs";
import AddGallery from "../Parishes/AddGallery";
import AddBlog from "../Blogs/AddBlog";

const Dashboard = () => {
    const [isSuperAdmin, setisSuperAdmin] = useState(false)

    useEffect(() => {
        const check = async () => {
            let Token = localStorage.getItem("LoggedInUserTokenID");

            const response = await fetch(`${process.env.REACT_APP_API_URI}/get-admin`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${Token}`,
                },
            })

            await response.json()

            
                .then((res) => {
                    if (res.status === "ok") {
                        if(res.admin.isSuperAdmin){
                          setisSuperAdmin(true)
                        }
                        console.log(res);
                    } else {
                        console.log(res);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        };

        check()
    }, []);

    const routes = [
        {
            path: "/dashboard/add-users",
            main: () => <AddUser />,
        },
        {
            path: "/dashboard/logs",
            main: () => <Logs />,
        },
        {
            path: "/dashboard/new-campaign",
            main: () => <AddCampaign />,
        },
        {
            path: "/dashboard/update",
            exact: true,
            main: () => <UpdateSearch />,
        },
        {
            path: "/dashboard/update-campaign/:id",
            main: () => <UpdateCampaign />,
        },

        {
            path: "/dashboard/update-parish/:id",
            main: () => <UpdateParish />,
        },

        {
            path: "/dashboard/update-patron/:id",
            main: () => <UpdatePatron />,
        },
        {
            path: "/dashboard/update-blog/:id",
            main: () => <UpdateBlog />,
        },

        {
            path: "/dashboard/new-parish",
            main: () => <AddParish />,
        },
        {
            path: "/dashboard/add-gallery",
            main: () => <AddGallery />,
        },
        {
            path: "/dashboard/new-patron",
            main: () => <AddPatron />,
        },
        {
            path: "/dashboard/add-author",
            main: () => <AddAuthor />,
        },
        {
            path: "/dashboard/add-blog",
            main: () => <AddBlog />,
        },
    ];
    return (
        <div>
            <Router>
                <div className="dashboard-container">
                    <div className="sidebar">
                        {isSuperAdmin ? <div className="nav">
                            <Link to="/dashboard/add-users">
                                <div className="nav-item">Add Users</div>
                            </Link>
                            <Link to="/dashboard/logs">
                                <div className="nav-item">Logs</div>
                            </Link>
                        </div> : ""}
                        
                        <div className="nav">
                            <Link to="/dashboard/new-campaign">
                                <div className="nav-item">Add campaign </div>
                            </Link>

                            <Link to="/dashboard/new-parish">
                                <div className="nav-item">Add Parish</div>
                            </Link>

                            <Link to="/dashboard/new-patron">
                                <div className="nav-item">Add Patron</div>
                            </Link>
                            <Link to="/dashboard/add-gallery">
                                <div className="nav-item">Add gallery for parish</div>
                            </Link>
                        </div>

                        <div className="nav">
                            <Link to="/dashboard/add-author">
                                <div className="nav-item">Add Author </div>
                            </Link>
                            <Link to="/dashboard/add-blog">
                                <div className="nav-item">Add Blog </div>
                            </Link>

                        </div>

                        <div className="nav">
                            <Link to="/dashboard/update">
                                <div className="nav-item">Update </div>
                            </Link>
                        </div>


                        <div className="nav logout">
                            <GoogleLogoutBtn />
                        </div>
                    </div>

                    <div className="dashboard-content">
                        <Switch>
                            {routes.map((route, index) => (
                                <Route
                                    key={index}
                                    path={route.path}
                                    exact={route.exact}
                                    children={route.main}
                                />
                            ))}
                        </Switch>
                    </div>
                </div>
            </Router>
        </div>
    );
};

export default Dashboard;
