import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import Post from "../../API/Post";
import Error from "../Error/Error";
import Form from "../Form/Form";
import Success from "../Success/Success";
import "./AddGallery.css";


const AddGallery = () => {
    const [files, setFiles] = useState([]);
    const [nerchapettiId, setnerchapettiId] = useState("");
    const [isLoading, setisLoading] = useState(false);
    const [error, seterror] = useState(false);
    const [success, setsuccess] = useState(false);
    const [images, setimages] = useState([]);

    const submitForm = (e) => {
        e.preventDefault();
        console.log(nerchapettiId);
        console.log(images);


        const formData = new FormData();
        formData.append("nerchapettiId", nerchapettiId);

        for (const key of Object.keys(images)) {

            formData.append('image', images[key])
        }


        Post(`${process.env.REACT_APP_API_URI}/gallery`, formData)
            .then((res) => {
                if (res.status === "ok") {
                    console.log(res);
                    setisLoading(false);
                    setsuccess(true);
                } else {
                    setisLoading(false);
                    seterror(true);
                    console.log(res);
                }
            })
            .catch((err) => {
                setisLoading(false);
                seterror(true);
                console.log(err);
            });
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: "image/*",
        onDrop: (acceptedFiles) => {
            acceptedFiles.forEach((file) => {
                setimages(images => [...images, file])
            });
            setFiles(
                acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            );
        },
    });

    const thumbs = files.map((file) => (
        <div className="thumb" key={file.name}>
            <div className="thumbInner">
                <img src={file.preview} alt="" className="img" />
            </div>
        </div>
    ));

    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            files.forEach((file) => URL.revokeObjectURL(file.preview));
        },
        [files]
    );




    if (success) {
        return (
            <Success success={success} message={"Gallery added Successfully"} />
        );
    }

    if (isLoading) {
        return <div className="loader"></div>;
    }

    return (
        <>
            <Error error={error} />
            <form
                enctype="multipart/form-data"
                onSubmit={(e) => submitForm(e)}
                className="gallery-form"
            >
                <input
                    className="input"
                    placeholder="nerchapetti ID"
                    type="text"
                    value={nerchapettiId}
                    onChange={(e) => setnerchapettiId(e.target.value)}
                />

                <section className="multiple-image-drop">
                    <div {...getRootProps({ className: "dropzone" })}>
                        <input multiple onChange={(e) => setimages(e.target.files)} {...getInputProps()} />
                        <p>
                            Drag 'n' drop some files here, or click to select
                            files
                        </p>
                    </div>
                    <aside className="thumbsContainer">{thumbs}</aside>
                </section>

                <input type="submit" className="submit" value="Add Images" />
            </form>
        </>
    );
};

export default AddGallery;
