import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Post from "../../API/Post";
import Form from "../Form/Form";
import Error from "../Error/Error";
import Success from "../Success/Success";
import "./AddParish.css";
import { FaTrash } from 'react-icons/fa';

const AddParish = () => {
    const [plan, setplan] = useState("")
    const [image, setimage] = useState("");
    const [churchName, setchurchName] = useState("");
    const [vendorName, setvendorName] = useState()
    const [content, setcontent] = useState("");
    const [location, setlocation] = useState("");
    const [pin, setpin] = useState("")
    const [email, setemail] = useState("");
    const [qurbanaEmail, setqurbanaEmail] = useState("")

    // const [vicar, setvicar] = useState("");
    // const [vicarEmail, setvicarEmail] = useState("");
    // const [vicarPhone, setvicarPhone] = useState("");

    // const [covicar, setcovicar] = useState("");
    // const [covicarEmail, setcovicarEmail] = useState("");
    // const [covicarPhone, setcovicarPhone] = useState("");

    // const [trustee, settrustee] = useState("");
    // const [trusteeEmail, settrusteeEmail] = useState("");
    // const [trusteePhone, settrusteePhone] = useState("");

    // const [secretarty, setsecretarty] = useState("");
    // const [secretartyEmail, setsecretartyEmail] = useState("");
    // const [secretartyPhone, setsecretartyPhone] = useState("");

    // const [accountant, setaccountant] = useState("");
    // const [accountantEmail, setaccountantEmail] = useState("");
    // const [accountantPhone, setaccountantPhone] = useState("");
    const [committeMembers, setcommitteMembers] = useState([]);

    const [offerings, setofferings] = useState([]);
    const [qurbana, setqurbana] = useState([])


    const [bankName, setbankName] = useState("");
    const [branchName, setbranchName] = useState("");
    const [accountNo, setaccountNo] = useState("");
    const [IFSC, setIFSC] = useState("");
    const [accountHolder, setaccountHolder] = useState("");

    const [isLoading, setisLoading] = useState(false);
    const [error, seterror] = useState(false);
    const [success, setsuccess] = useState(false);

    const [nerchapettiId, setnerchapettiId] = useState("")

    const clearForm = () => {
        setchurchName("")
        setcontent("")
        setlocation("")
        setpin("")
        setemail("")
        setimage("")
        // setvicar("")
        // setvicarEmail("")
        // setvicarPhone("")
        // setcovicar("")
        // setcovicarEmail("")
        // setcovicarPhone("")
        // settrustee("")
        // settrusteeEmail("")
        // settrusteePhone("")
        // setsecretarty("")
        // setsecretartyEmail("")
        // setsecretartyPhone("")
        // setaccountant("")
        // setaccountantEmail("")
        // setaccountantPhone("")
        setcommitteMembers([])
        setofferings([])
        setqurbana([])
        setbankName("")
        setbranchName("")
        setaccountNo("")
        setIFSC("")
        setaccountHolder("")
        setvendorName('')
    }


    const deleteCommitteeMember = (member) => {
        let filteredCommitteeMembers = committeMembers.filter(mem => mem.name !== member.name)
        setcommitteMembers(filteredCommitteeMembers)  
    }

    const deleteOffer = (offer) => {
        let filteredOffer = offerings.filter(offering => offering.Name !== offer.Name)
        setofferings(filteredOffer)  
    }
    
    const deleteQurbana = (q) => {
        let filteredQurbana = qurbana.filter(qur => qur.name !== q.name)
        setqurbana(filteredQurbana)  
    }

    const showMembers = (members) => {
        return members.map((member) => (
            <div className="member-list">
            <li>{member.name}</li>
            <FaTrash onClick={e => deleteCommitteeMember(member)} style={{color: "red", cursor: "pointer"}} />
            </div>
        ));
    };
    const showOfferings = (offers) => {
        return offers.map((offer) => (
            <div className="member-list">
            <li>{offer.Name}</li>
            <FaTrash onClick={e => deleteOffer(offer)} style={{color: "red", cursor: "pointer"}} />
            </div>
        ));
    };
    const showQurbana = (qurbanaList) => {
        return qurbanaList.map((qurbana) => (
            <div className="member-list">
            <li>{qurbana.name} - {qurbana.amount}Rs</li>
            <FaTrash onClick={e => deleteQurbana(qurbana)} style={{color: "red", cursor: "pointer"}} />
            </div>
        ));
    };

    const handleClickAfterSuccess = () => {
      setsuccess(false)
    }

    const AddCommitte = () => {
        const [committeMemberName, setcommitteMemberName] = useState("");
        const [committeMemberPosition, setcommitteMemberPosition] = useState("");
        const [committeMemberPhone, setcommitteMemberPhone] = useState("");
        const [addQurbanaError, setaddQurbanaError] = useState(false)

        const addMember = () => {
            if(committeMemberName === "" || committeMemberPosition === "" || committeMemberPhone ===""){
                setaddQurbanaError(true)
                setcommitteMembers(committeMembers)
              } else{
                setcommitteMembers((state) => [
                    ...state,
                    {
                        name: committeMemberName,
                        position: committeMemberPosition,
                        phoneNumber: committeMemberPhone,
                    },
                ]);
              }
            
        };

        return (
            <div className="addcommitte">
                <Form
                    n="1"
                    label="Member Name"
                    type="text"
                    placeholder="Name"
                    value={committeMemberName}
                    setValue={setcommitteMemberName}
                />
                <Form
                    n="2"
                    label="Member Position"
                    type="text"
                    placeholder="Member Position"
                    value={committeMemberPosition}
                    setValue={setcommitteMemberPosition}
                />
                <Form
                    n="3"
                    label="Member Phone Number"
                    type="number"
                    placeholder="Member phone Number"
                    value={committeMemberPhone}
                    setValue={setcommitteMemberPhone}
                />
                {addQurbanaError ? <p style={{color: "red"}}> Fill all the details of Member </p> : ""}
                <div className="btn add" onClick={addMember}>
                    Add Member
                </div>
            </div>
        );
    };

    const AddOfferings = () => {
        const [offeringName, setofferingName] = useState("");
        // const [offeringDetails, setofferingDetails] = useState("");
        // const [offeringPrice, setofferingPrice] = useState("");
        const [addofferingError, setaddofferingError] = useState(false)

        const addOffer = () => {
            if(offeringName === "" ){
                setaddofferingError(true)
                setofferings(offerings)
              } else{
                setofferings((state) => [
                    ...state,
                    {
                        Name: offeringName,
                        // details: offeringDetails,
                        // price: offeringPrice,
                    },
                ]);
              }
            
        };
        return (
            <div className="addoffering">
                <Form
                    n="1"
                    label="Offerings Name"
                    type="text"
                    placeholder="offerings Name"
                    value={offeringName}
                    setValue={setofferingName}
                />

                {addofferingError ? <p style={{color: "red"}}> Fill all the details of Member </p> : ""}
                <div className="btn add" onClick={addOffer}>
                    Add Offer
                </div>
            </div>
        );
    };

    const Qurbana = () => {
        const [qurbanaName, setqurbanaName] = useState("")
        const [qurbanaAmount, setqurbanaAmount] = useState("")
        const [addQurbanaError, setaddQurbanaError] = useState(false)

        const addQurbana = () => {
            if(qurbanaName === "" || qurbanaAmount === ""){
                setaddQurbanaError(true)
                setqurbana(qurbana)
              } else{
                setqurbana((state) => [
                    ...state,
                    {
                        name: qurbanaName,
                        amount: qurbanaAmount,
                    },
                ]);
              }
            
        };

        return (
            <div className="addcommitte">
                <Form
                    n="1"
                    label="Qurbana Name"
                    type="text"
                    placeholder="Qurbana Name"
                    value={qurbanaName}
                    setValue={setqurbanaName}
                />
                <Form
                    n="2"
                    label="Qurbana Amount"
                    type="text"
                    placeholder="Qurbana Amount"
                    value={qurbanaAmount}
                    setValue={setqurbanaAmount}
                />
               
                {addQurbanaError ? <p style={{color: "red"}}> Fill all the details of Member </p> : ""}
                <div className="btn add" onClick={addQurbana}>
                    Add Qurbana
                </div>
            </div>
        );
    };

    const submitForm = (e) => {
        
        if(!offerings){
            seterror(true)
            return
        }

        setisLoading(true);
        e.preventDefault();
        const formData = new FormData();

        formData.append("plan", plan)
        formData.append("vendorName", vendorName)
        formData.append(
            "about",
            JSON.stringify({ churchName, content, location, pin,  email })
        );
        formData.append("committee", JSON.stringify(committeMembers));
        formData.append("offerings", JSON.stringify(offerings));
        formData.append("qurbana", JSON.stringify(qurbana))
        formData.append(
            "bankAccount",
            JSON.stringify({
                bankName,
                branchName,
                accountHolder,
                accountNo,
                IFSC,
            })
        );
        // formData.append(
        //     "vicar",
        //     JSON.stringify({
        //         name: vicar,
        //         email: vicarEmail,
        //         phoneNumber: vicarPhone,
        //     })
        // );
        // formData.append(
        //     "covicar",
        //     JSON.stringify({
        //         name: covicar,
        //         email: covicarEmail,
        //         phoneNumber: covicarPhone,
        //     })
        // );
        // formData.append(
        //     "trustee",
        //     JSON.stringify({
        //         name: trustee,
        //         email: trusteeEmail,
        //         phoneNumber: trusteePhone,
        //     })
        // );
        // formData.append(
        //     "secretary",
        //     JSON.stringify({
        //         name: secretarty,
        //         email: secretartyEmail,
        //         phoneNumber: secretartyPhone,
        //     })
        // );
        // formData.append(
        //     "accountant",
        //     JSON.stringify({
        //         name: accountant,
        //         email: accountantEmail,
        //         phoneNumber: accountantPhone,
        //     })
        // );
        formData.append('emailForQurbana', qurbanaEmail)
        formData.append("image", image);

        Post(`${process.env.REACT_APP_API_URI}/add-parish`, formData)
            .then((res) => {
                if (res.status === "ok") {
                    clearForm()
                    console.log(res);
                    setnerchapettiId(res.nerchapettiId)
                    setisLoading(false);
                    setsuccess(true);
                } else {
                    setisLoading(false);
                    seterror(true);
                    console.log(res);
                }
            })
            .catch((err) => {
                setisLoading(false)
                seterror(true);
                console.log(err);
            });
    };

    if (success) {
        return (
          <>
            <Success success={success} message={"Parish Created Successfully"}/>
            <h1>Nerchapetti ID : {nerchapettiId}</h1>
            <Link to="/dashboard/new-parish" >
            <button className="button" onClick={handleClickAfterSuccess}>Add New Parish</button>
            </Link>
          </>
        );
    }

    if (isLoading) {
        return <div className="loader"></div>;
    }

    return (
        <div className="create-container add-parish">
           <Error  error={error} />
            <h1 className="main-title">Add Parish</h1>
            <form className="create" onSubmit={(e) => submitForm(e)}>
                <div className="about-details">

                    <div className="question-container">
                        <div className="circle">0</div>
                        <h1 className="question">Select the plan</h1>
                        <select name="plan" id="" value={plan} onChange={e => setplan(e.target.value)}>
                            <option value="nill">Select a Plan</option>
                            <option value="basic">Basic</option>
                            <option value="premium">Premium</option>
                        </select>
                    </div>
                    <Form
                        n="1"
                        label="Church Name"
                        type="text"
                        placeholder="Church Name"
                        value={churchName}
                        setValue={setchurchName}
                        required={true}
                    />
                    <Form
                        n="1"
                        label="Vendor Name (Can only be alphabets !!)"
                        type="text"
                        placeholder="vendor name"
                        value={vendorName}
                        setValue={setvendorName}
                        required={true}
                    />
                    <Form
                        n="2"
                        label="About"
                        type="ckeditor"
                        placeholder="About"
                        value={content}
                        setValue={setcontent}
                        required={true}
                    />
                    <Form
                        n="3"
                        label="location"
                        type="text"
                        placeholder="Location"
                        value={location}
                        setValue={setlocation}
                        required={true}
                    />
                    <Form
                        n="3"
                        label="Pin"
                        type="number"
                        placeholder="PIN"
                        value={pin}
                        setValue={setpin}
                        required={true}
                    />
                    <Form
                        n="3"
                        label="Primary Email Address"
                        type="email"
                        placeholder="email"
                        value={email}
                        setValue={setemail}
                        required={true}
                    />

                    <Form
                        n="6"
                        label="Image"
                        type="image"
                        placeholder="Image"
                        setValue={setimage}
                    />
                </div>

                {/* <h1 className="add-title">Add Core Members</h1>
                <div className="coremember-container">
                    <div className="coremember">
                        <h2 className="title">vicar</h2>
                        <Form
                            n="1"
                            label=""
                            type="text"
                            placeholder="vicar Name"
                            value={vicar}
                            setValue={setvicar}
                            required={true}
                        />

                        <Form
                            n="1"
                            label=""
                            type="email"
                            placeholder="vicar Email"
                            value={vicarEmail}
                            setValue={setvicarEmail}
                        />

                        <Form
                            n="1"
                            label=""
                            type="text"
                            placeholder="Vicar Phone Number"
                            value={vicarPhone}
                            setValue={setvicarPhone}
                            required={true}
                        />
                    </div>

                    <div className="coremember">
                        <h2 className="title">Co vicar</h2>
                        <Form
                            n="1"
                            label=""
                            type="text"
                            placeholder="Covicar Name"
                            value={covicar}
                            setValue={setcovicar}
                            required={false}
                        />

                        <Form
                            n="1"
                            label=""
                            type="email"
                            placeholder="Co vicar Email"
                            value={covicarEmail}
                            setValue={setcovicarEmail}
                            required={false}
                        />

                        <Form
                            n="1"
                            label=""
                            type="text"
                            placeholder="Co Vicar Phone Number"
                            value={covicarPhone}
                            setValue={setcovicarPhone}
                            required={false}
                        />
                    </div>

                    <div className="coremember">
                        <h2 className="title">Trustee</h2>
                        <Form
                            n="1"
                            label=""
                            type="text"
                            placeholder="Trustee Name"
                            value={trustee}
                            setValue={settrustee}
                            required={true}
                        />

                        <Form
                            n="1"
                            label=""
                            type="email"
                            placeholder="Trustee Email"
                            value={trusteeEmail}
                            setValue={settrusteeEmail}
                        />

                        <Form
                            n="1"
                            label=""
                            type="text"
                            placeholder="Trustee Phone Number"
                            value={trusteePhone}
                            setValue={settrusteePhone}
                            required={true}
                        />
                    </div>

                    <div className="coremember">
                        <h2 className="title">Secretary</h2>
                        <Form
                            n="1"
                            label=""
                            type="text"
                            placeholder="Secretary Name"
                            value={secretarty}
                            setValue={setsecretarty}
                            required={true}
                        />

                        <Form
                            n="1"
                            label=""
                            type="email"
                            placeholder="Secretary Email"
                            value={secretartyEmail}
                            setValue={setsecretartyEmail}
                        />

                        <Form
                            n="1"
                            label=""
                            type="text"
                            placeholder="Secretary Phone Number"
                            value={secretartyPhone}
                            setValue={setsecretartyPhone}
                            required={true}
                        />
                    </div>

                    <div className="coremember">
                        <h2 className="title">Accountant</h2>
                        <Form
                            n="1"
                            label=""
                            type="text"
                            placeholder="Accountant Name"
                            value={accountant}
                            setValue={setaccountant}
                            required={false}
                        />

                        <Form
                            n="1"
                            label=""
                            type="email"
                            placeholder="Accountant Email"
                            value={accountantEmail}
                            setValue={setaccountantEmail}
                            required={false}
                        />

                        <Form
                            n="1"
                            label=""
                            type="text"
                            placeholder="Accountant Phone Number"
                            value={accountantPhone}
                            setValue={setaccountantPhone}
                            required={false}
                        />
                    </div>
                </div> */}

                <h1 className="add-title">Add Committe Members</h1>
                <div className="committe-container">
                    <AddCommitte />
                    <div className="committee-members">
                        <h1>Committe Members</h1>
                        <ul>{showMembers(committeMembers)}</ul>
                    </div>
                </div>

                <h1 className="add-title">Add Offerings</h1>
                <div className="offerings-container">
                    <AddOfferings />
                    <div className="offers">
                        <h1>Offerings</h1>
                        <ul>{showOfferings(offerings)}</ul>
                    </div>
                </div>

                <h1 className="add-title">Add Qurbana</h1>
                <div className="offerings-container">
                   
                    <Qurbana />
                    <div className="offers">
                        <h1>Qurbana</h1>
                        <ul>{showQurbana(qurbana)}</ul>
                    </div>
                    <Form 
                        n="0"
                        label="Email for sending qurbana details"
                        type="email"
                        placeholder="email"
                        value={qurbanaEmail}
                        setValue={setqurbanaEmail}
                        required={true}
                    />
                </div>

                <h1 className="add-title">Bank Details</h1>
                <div className="bankdetails">
                    <Form
                        n="1"
                        label="Bank Name"
                        type="text"
                        placeholder="Bank Name"
                        value={bankName}
                        setValue={setbankName}
                        required={true}
                    />
                    <Form
                        n="2"
                        label="Branch Name"
                        type="text"
                        placeholder="Branch Name"
                        value={branchName}
                        setValue={setbranchName}
                        required={true}
                    />
                    <Form
                        n="3"
                        label="Account Number"
                        type="number"
                        placeholder="Account Number"
                        value={accountNo}
                        setValue={setaccountNo}
                        required={true}
                    />

                    <Form
                        n="4"
                        label="IFSC"
                        type="text"
                        placeholder="IFSC"
                        value={IFSC}
                        setValue={setIFSC}
                        required={true}
                    />
                    <Form
                        n="4"
                        label="Account Holder"
                        type="text"
                        placeholder="Account Holder"
                        value={accountHolder}
                        setValue={setaccountHolder}
                        required={true}
                    />

                </div>

                <input type="submit" className="btn" value="Create Parish" />
            </form>
        </div>
    );
};

export default AddParish;
